var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
require('foundation-sites');
require('foundation-sites/interchange')
require('foundation-sites/equalizer')
require('easie');
require('placeholders');
require('select2');
var detectIE = require('detectie');

$(document).foundation();

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}

function isMobileNav() {
	return $("header a.menu-toggle").height() > 0;
}

$(document).ready(function(){
	
	//Mobile Nav
	$("header a.menu-toggle").click(function(){
		$("header nav ul.menu").slideToggle();
		$("header nav .mobile-overlay").fadeToggle();
		$(this).fadeOut(function(){
			$(this).toggleClass('open').fadeIn();
		});
		$("ul.menu > li.menu-item-has-children > a").each(function(){
			if($(this).siblings('ul.sub-menu').is(":visible")) {
				$(this).click();
			}
		});
		return false;
	});

	$("ul.menu > li.menu-item-has-children > a").click(function(){
		if(isMobileNav()) {
			$(this).siblings('ul.sub-menu').slideToggle();
			$('body').toggleClass('noscroll');
			$(this).toggleClass('open'); 
			return false;
		}
	});

	$("ul.menu > li.menu-item-has-children > a").hover(
     		function(){
     			if(!isMobileNav()) {
	     			$(this).parent().addClass('hover'); $('.desktop-overlay').stop().fadeTo(400,1); $('input').blur();
	     		}
     		},
     		function(){
     			if(!isMobileNav()) {
	     			$(this).parent().removeClass('hover'); $('.desktop-overlay').stop().fadeTo(400,0,function(){$(this).hide();});
	     		}
     		}
	);
	$("ul.menu > li.menu-item-has-children > ul.sub-menu").hover(
     		function(){
     			if(!isMobileNav()) {
	     			$(this).parent().addClass('hover'); $('.desktop-overlay').stop().fadeTo(400,1); $('input').blur();
	     		}
     		},
     		function(){
     			if(!isMobileNav()) {
	     			$(this).parent().removeClass('hover'); $('.desktop-overlay').stop().fadeTo(400,0,function(){$(this).hide();});
	     		}
     		}
	);

	if(!$("header a.menu-toggle").is(':visible')){
		$("ul.menu > li.menu-item-has-children > a").hover(
       		function(){ $(this).parent().addClass('hover'); $('.desktop-overlay').stop().fadeTo(400,1); $('input').blur(); },
       		function(){ $(this).parent().removeClass('hover'); $('.desktop-overlay').stop().fadeTo(400,0,function(){$(this).hide();}); }
		);
		$("ul.menu > li.menu-item-has-children > ul.sub-menu").hover(
       		function(){ $(this).parent().addClass('hover'); $('.desktop-overlay').stop().fadeTo(400,1); $('input').blur(); },
       		function(){ $(this).parent().removeClass('hover'); $('.desktop-overlay').stop().fadeTo(400,0,function(){$(this).hide();}); }
		);
	}

	$(".mobile-overlay").click(function(){
		$("header a.menu-toggle").click();
	});

	// $("ul#menu-header-menu .sub-menu-inner > li > a").click(function(){
	// 	return false;
	// });

	//Accordion
	$("ul.category-nav > li > a, ul.category-nav > li > span.category, ul.accordion > li > a").click(function(){
		if($(this).parents('li.open').length){
			$(this).parents('li').removeClass('open').find('.children, .sub-menu').slideUp();
		}else{
			$('ul.category-nav li.open, ul.accordion li.open').removeClass('open').find('.children, .sub-menu').slideUp();
			var $expanding = $(this).parent().find('.children, .sub-menu');

			$expanding.css('height', 'auto');
			var expandingHeight = $expanding.height();
			$expanding.css('height', '');

			$(this).parent().addClass('open').find('.children, .sub-menu').slideDown(function(){
				if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    				$('html, body').animate({
    				  scrollTop: $expanding.parents('li').offset().top - $('header:visible').outerHeight()
    				});
    			}
			});

			if( !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
				if($(window).scrollTop() + $(window).height() <  $expanding.offset().top + expandingHeight ) {
    				$('html, body').animate({
    				  scrollTop: $expanding.offset().top + expandingHeight - $(window).height()
    				});
				}
			}
		}
		return false;
	});
	$("li.menu-item.current-menu-item").parents('li').addClass('open').find('ul').show();

	//Drop Cap
	var dropcap = "<span class='dropcap'>" + $('.intro p').first().text().substr(0,1) + "</span>";
		$('.intro p').first().html(dropcap + $('.intro p').first().text().substr(1));
		$('.intro p').first().addClass('first-ppg');
		if(detectIE() || (navigator.appVersion.indexOf("Win")!=-1)){ $('.dropcap').css('top', parseInt($('.dropcap').css('top')) - 9); }

	//About Page Animations
	if($('body').hasClass('page-template-template-about-php')){

		$(window).load(function(){
			$('.loader').fadeOut(1000);
			$('.about-hero__bg, .hero__headline, .about-intro__inner').removeClass('hidden');
      });
      $('.parallax-background').fadeTo(1000,1,function(){	
			});
        $(window).scroll(function() {
            var yPos = $(window).scrollTop() / 2;
            $('.parallax-background').css({
            	'transform': 'translateY(' + yPos + 'px)',
            	'-webkit-transform': '-webkit-translateY(' + yPos + 'px)'
            });
        });                                         


		$(window).scroll(function() {
		 	/*
		 		$(".about-intro").css("opacity", 0 + $(window).scrollTop() / 400);
	 		}*/
	 		$(".about-hero__headline").css("opacity", 1 - $(window).scrollTop() / 400);
			$('.about-2up-block__text-wrapper, .about-2up-overlay__content').each(function(){
				if($(window).scrollTop() + $(window).height() > $(this).height()+$(this).offset().top) {
					$(this).removeClass('hidden');
				}
			});
			/*$('.about-3up__block').each(function(i){
				if($(window).scrollTop() + $(window).height() > $('.about-3up').height()+$('.about-3up').offset().top) {
					$(this).removeClass('hidden');
					$(this).delay(300*i).fadeTo(300,1);
				}
			});*/
		});
		if($('.salon-count').length && !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ){
			var original_count = $('.salon-count').text();
			var count_animated = false;
			$('.salon-count').text(Math.ceil(original_count*0.95));
			$(window).scroll(function() {
   				if($(window).scrollTop() + $(window).height() + 100 > $('.salon-count').height()+$('.salon-count').offset().top+$('footer').height() && !count_animated) {
   					count_animated = true;
					jQuery({someValue: $('.salon-count').text()}).animate({someValue: original_count}, {
					duration: 1500,
					step: function() {
						$('.salon-count').text(numberWithCommas(Math.ceil(this.someValue)));
					},
					done: function() {
						$('.salon-count').text(numberWithCommas(original_count));
					}
					});
   				}
   			});
		}

	//Category Animations
	} else if($('body').hasClass('category') || $('body').hasClass('archive')) {

		$('.loader').fadeOut(800);
		$('section.intro').fadeTo(1000,1,function(){
			setTimeout(function(){
				$('section.intro .hidden').removeClass('hidden');
				$('.products .fade-effect.hidden').each(function(){
   					if($(window).scrollTop() + $(window).height() > $(this).offset().top) {
   						$(this).removeClass('hidden');
   					}
   				});
			}, 250);
	    });
		$(window).scroll(function() {
			$('.products .fade-effect.hidden').each(function(){
   				if($(window).scrollTop() + $(window).height() > $(this).offset().top + 50) {
   					$(this).removeClass('hidden');
   				}
   			});
		});

	//Product Animations
	} else if($('body').hasClass('single-product')){

		$('.loader').fadeOut(800);
		$('section.intro').fadeTo(1000,1,function(){
			setTimeout(function(){
				$('section.intro .hidden').removeClass('hidden');
				$('#product-main-info.fade-effect.hidden').each(function(){
   					if($(window).scrollTop() + $(window).height() > $(this).offset().top) {
   						$(this).removeClass('hidden');
   					}
   				});
			}, 250);
	    });
		$('.fade-effect.hidden').each(function(){
   			if($(window).scrollTop() + $(window).height() > $(this).offset().top + 50) {
   				$(this).removeClass('hidden');
   			}
   		});
		$(window).scroll(function() {
			$('.fade-effect.hidden').each(function(){
   				if($(window).scrollTop() + $(window).height() > $(this).offset().top + 50) {
   					$(this).removeClass('hidden');
   				}
   			});
		});

	} else {

		$(window).load(function(){
			$('.loader').fadeOut(800,function(){
				$('h2.fade-effect.hidden').removeClass('hidden');
				setTimeout(function(){
						$('.page__featured-image.fade-effect.hidden').removeClass('hidden');
					},150);
				setTimeout(function(){
					$('#content.fade-effect.hidden').removeClass('hidden');
				},250);
			});
		});
	}

	//Salon Finder Functionality
	$(document).on( "map-initialized", function( event, map) {
		//Disable dragging on mobile devices
		if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
		    map.set('draggable', false);
		    map.set('zoomControl', true);
		    map.set('panControl', true);

			google.maps.event.addDomListener(map, 'tilesloaded', function(){
			    if($('#adjustPanControl').length==0){
			        $('div.gmnoprint:eq(-4)').parent().parent().wrap('<div id="adjustPanControl" />');
			        $("#adjustPanControl").fadeIn();
			    }
			    if($('#adjustZoomControl').length==0){
			        $('#adjustPanControl').next().wrap('<div id="adjustZoomControl" />');
			        $("#adjustZoomControl").fadeIn();
			    }
			});

		}
	});
	$('#map-container').on('touchstart', '.gmnoprint div[title^=Pan]', function () {
        $(this).trigger('click');
        return false;
    });

	if( !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
		$('select#cat').select2({
			minimumResultsForSearch: -1
		}).on("select2-open", function() {
			$(".select2-drop").outerWidth($(".select2-drop").outerWidth()+1);
		});
		$('.select2-results').on('click', 'li', function(){
		    $(this).find('li').slideToggle();
		});
	}

	var markers;
	$(document).on( "markers-added", function( event, added_markers ) {
		$.each(added_markers, function() {
			markers = added_markers;
			var currentMarker = this;
  			window.google.maps.event.addListener(this.__gmarker, 'click', function() {
				$('#map_sidebar .results_entry.selected').removeClass('selected');
				$('#map_sidebar .results_entry span.location_name:contains("'+this.title+'")').parents('.results_entry').addClass('selected');
				$.each(markers, function() {
					var baseURL = this.__markerImage.substr(0,this.__markerImage.lastIndexOf("/") + 1);
					if(currentMarker == this) {
						this.__gmarker.setIcon(baseURL + "pin.png");
					}else{
						this.__gmarker.setIcon(baseURL + "pin-small.png");
					}
				});
  			});
		});
	});

	$(document).on('click', '.gm-style-iw + div', function() {
		$('#map_sidebar .results_entry.selected').removeClass('selected');
		$.each(markers, function() {
			var baseURL = this.__markerImage.substr(0,this.__markerImage.lastIndexOf("/") + 1);
			this.__gmarker.setIcon(baseURL + "pin.png");
		});
	});

	$(document).on('click', '#map_sidebar .results_entry', function(e){
		$('#map_sidebar .results_entry.selected').removeClass('selected');
		$(this).addClass('selected');
		selected_entry = $(this).find('.location_name').text();
		$.each(markers, function() {
			var baseURL = this.__markerImage.substr(0,this.__markerImage.lastIndexOf("/") + 1);
			if(selected_entry == this.__title) {
				this.__gmarker.setIcon(baseURL + "pin.png");
			}else{
				this.__gmarker.setIcon(baseURL + "pin-small.png");
			}
		});
	});

	//Using browser detection in lieu of momentum scroll feature detection.
	if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
		$('.loader').hide();
		$('.fade-effect.hidden').each(function(){ $(this).removeClass('hidden'); });
		$('.salon-count').text(numberWithCommas($('.salon-count').text()));
	}

	//Close select menu when tapping away on iOS
	$('*').click(function(e){
		if(!$(e.target).is('select')){
			$('select:focus').blur();
		}
	});

	//Manage map default state
	$(document).on('click', '#sl_div input#addressSubmit', function() {
		$('#map_sidebar').html("<div class='loader'>"+$(".loader").html()+"</div>");
		$('#sl_div #map-container').removeClass('large-centered').addClass('large-push-4');
		$('#sl_div #results-container').removeClass('hide');
	});
	if(getUrlVars()["address"] && getUrlVars()["address"].length){
		$('#map_sidebar').html("<div class='loader'>"+$(".loader").html()+"</div>");
		$('#sl_div #map-container').removeClass('large-centered').addClass('large-push-4');
		$('#sl_div #results-container').removeClass('hide');
	}

	//Event Tracking
	$(document).on('click', '#slider a', function() {
  		ga('send', 'event', 'button', 'click', 'slider-buttons');
	});
	$(document).on('click', '.box.generic a', function() {
  		ga('send', 'event', 'button', 'click', 'homepage-callout');
	});
	$(document).on('click', '.box.salon input.button', function() {
  		ga('send', 'event', 'button', 'click', 'homepage-salonfinder');
	});
	$(document).on('click', '.salon-finder-wide input.button', function() {
  		ga('send', 'event', 'button', 'click', 'category-salonfinder');
	});
	$(document).on('click', '#about-bottom a', function() {
  		ga('send', 'event', 'button', 'click', 'about-contact');
	});
	$(document).on('click', '#sl_div input#addressSubmit', function() {
  		ga('send', 'event', 'button', 'click', 'salonfinder-search');
	});


});